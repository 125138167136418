import { Wistia } from 'wistia_namespace.js';
import { getCookie } from 'utilities/cookie.js';
import { wlog } from 'utilities/wlog.js';

// matches Vendor.hubspot_v2.name in the Wistia Rails app
const HUBSPOT_V2_INTEGRATION_NAME = 'hubspot_v2';

// cookie search polling settings
const COOKIE_SEARCH_ATTEMPT_COUNT = 120;
const COOKIE_SEARCH_TIMEOUT_MILLISECONDS = 1000;

// Hubspot tracking code documentation:
// http://developers.hubspot.com/docs/methods/tracking_code_api/tracking_code_overview
export const getPlugin = ({ getHubspotUtk }) => ({
  onFind: (video) => {
    getHubspotUtk()
      .then((utk) => {
        video.foreignData({
          ...video.foreignData(),
          hubspot_utk: utk,
        });
      })
      .catch((e) => {
        // only log out to the console that no hubspot utk was found if the video actually has
        // a hubspot form or a turnstile with a hubspot list
        if (video.hasPlugin('hubspotForm') || video.plugin.turnstile?.options.list != undefined) {
          wlog.error(e);
        }
      });
  },
});

export const defaultGetHubspotUtk = () => {
  return new Promise((resolve, reject) => {
    let cookie;

    const findUtk = (remainingTries) => {
      cookie = getCookie('hubspotutk');

      if (cookie) {
        // set the stopGo state to true and pass the cookie along.
        resolve(cookie);
      } else if (remainingTries > 0) {
        setTimeout(findUtk, COOKIE_SEARCH_TIMEOUT_MILLISECONDS, remainingTries - 1);
      } else {
        reject(new Error('no Hubspot cookie found'));
      }
    };

    findUtk(COOKIE_SEARCH_ATTEMPT_COUNT);
  });
};

export const registerPlugin = () => {
  const plugin = getPlugin({
    getHubspotUtk: defaultGetHubspotUtk,
  });
  Wistia.integrations.register(HUBSPOT_V2_INTEGRATION_NAME, plugin);
};
